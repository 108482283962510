<template>
  <!-- 套餐卡添加 -->
  <div class="box100 flexCB">
    <div class="billLeft">
      <div class="leftCont">
        <div class="box100 flexCB">
          <div class="leftTabBox">
            <el-menu class="selectMenu"  unique-opened :default-active="mgid">
              <template v-for="item in serveTab">
                <el-submenu v-if="item.value&&item.value.length>0" :key="item.mgid" :index="item.mgid">
                  <template slot="title">{{ item.mgname }}</template>
                  <el-menu-item v-for='subItem in item.value' :key='subItem.mgid' :index="subItem.mgid" @click="selectVal(subItem)">
                    <template slot="title">{{ subItem.mgname }}</template>
                  </el-menu-item>
                </el-submenu>
                <el-menu-item v-else :key="item.id"  :index="item.mgid" @click="selectVal(item)">{{item.mgname}}</el-menu-item>
              </template>
            </el-menu>
          </div>
          <div class="leftList">
            <div class="box100 scrollBox" v-show="showServeList">
              <div v-for="(serItem, index) in serveList" :key="index" class="flexCB">
                <i class="el-icon-circle-plus" style="color: #44B557;cursor: pointer;margin-right:20px;font-size: 18px;" @click="handAdd(serItem)"></i>
                <div class="productItem flexCB">
                  <div class="flexCB">
                    <div class="item1">
                      <p class="item1_one">￥ {{serItem.mcaprice}}</p>
                      <p class="item1_two" @click.stop="handLink(serItem)">剩余{{serItem.remain}}</p>
                    </div>
                    <div class="item2">
                      <p class="item2_one">{{serItem.mcaname}}</p>
                      <p class="item2_two">{{serItem.mcacontent}}</p>
                      <p class="item2_three">有效期 {{serItem.mcastime}}—{{serItem.mcaetime}}</p>
                    </div>
                  </div>
                  <p class="rebeat" v-if="serItem.ded_num != 0" @click="handShowCoun(serItem)">赠{{serItem.ded_num}}张抵扣券</p>
                </div>
              </div>
            </div>
            <div class="notList" v-show="!showServeList">
              <img src="../../assets/img/noData.png"/>
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="billRig">
      <!-- 上 -->
      <div class="reRow flexCB">
        <div class="flexAC">
          <p style="margin-right: 16px;">客户查询</p>
          <el-popover placement="bottom-start" v-model="visible" trigger="manual">
            <div class="userRow" v-for="(item, index) in userList" :key="index" @click="selectUser(item)">{{ item.username }} / {{ item.uphone }}</div>
            <div slot="reference">
              <el-input placeholder="请输入客户手机号/名称" v-model="userKey"  @input="handGetList"></el-input>
            </div>
          </el-popover>
        </div>
        <el-button class="closebtn" @click="handClose">关闭本单</el-button>
      </div>
      <!-- 中 -->
      <div class="userBox">
        <div class="userInfo flexF" v-if="showUserInfo">
          <p class="userItem"><span style="width: 60px;">姓名</span><span class="utValue">{{ userInfo.username }}</span></p>
          <p class="userItem"><span style="width: 60px;">电话</span><span class="utValue">{{ userInfo.uphone }}</span></p>
        </div>
        <div class="userInfo" v-if="!showUserInfo" style="line-height: 50px;text-align: center">暂无客户信息</div>
      </div>
      <!-- 下 -->
      <div class="cardBox scrollBox">
        <div class="flexAC card_top" v-for="(serItem, index) in arr" :key="index"  v-show="showArrList">
          <i class="el-icon-error" style="color: #F44C8E;cursor: pointer;margin-right:20px;" @click="handDel(index)"></i>
          <div class="box100">
            <div class="proItem flexCB">
              <div class="flexCB">
                <div class="item1">
                  <p class="item1_one">￥{{serItem.mcaprice}}</p>
                  <p class="item1_two" @click.stop="handLink(serItem)">剩余 {{serItem.remain}}</p>
                </div>
                <div class="item2">
                  <p class="item2_one">{{serItem.mcaname}}</p>
                  <p class="item2_two">{{serItem.mcacontent}}</p>
                  <p class="item2_three">有效期 {{serItem.mcastime}}—{{serItem.mcaetime}}</p>
                </div>
              </div>
              <p class="rebeat" v-if="serItem.ded_num != 0" @click="handShowCoun(serItem)">赠{{serItem.ded_num}}张抵扣券</p>
            </div>
          </div>
          <div class="beaCont">
            <div v-if="serItem.beaId === ''" class="beatop">
              <img src="../../assets/img/beautician.png"/>
              <p @click="handBeat(index)">分配美容师</p>
            </div>
            <div v-if="serItem.beaId !== ''" class="beabot">
              <div class="codeBox">
                <p>{{ serItem.beaName }}</p>
              </div>
              <div class="flexCB">
                <div class="edbtn" @click="handBeat(index)">修改</div>
                <div class="delbtn" @click="handDeletd(serItem,index)">删除</div>
              </div>
            </div>
          </div>
        </div>
        <div class="notList" v-show="!showArrList">
          <img src="../../assets/img/noData.png"/>
          <p>暂无数据</p>
        </div>
      </div>
      <!-- 底部 -->
      <div class="sunBox">
        <div  class="summary flexCB">
          <div class="numary flexCB">
            <p><span style="color: #576271;">数量:</span><span>{{arr.length}}</span></p>
            <p><span style="color: #576271;">合计金额:</span><span>{{total}}</span></p>
          </div>
          <div class="sumbtn" @click="handSub">收款</div>
        </div>
      </div>
    </div>
    <!-- 分配美容师 -->
    <el-dialog title="分配美容师" class="baetit" :visible.sync="beaShow" width="1200px" center  :close-on-click-modal="false">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
    <!-- 关联服务 -->
    <el-dialog title="关联服务" :visible.sync="showLink" width="600px" center class="gyDialog serveDialog">
      <el-table
        height="250"
        :data="serveLink"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="服务名称"
          prop="servename">
        </el-table-column>
        <el-table-column
          label="剩余数量"
          prop="icsnum">
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 套餐卡关联抵扣券 -->
    <el-dialog title="赠送抵扣券" :visible.sync="showbea" width="600px" center class="gyDialog serveDialog">
      <el-table
        height="250"
        :data="beaLink"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="抵扣券名称"
          prop="mdname">
        </el-table-column>
        <el-table-column
          label="价格"
          prop="mdprice">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { markDetail, markgrouGet, incardAdd, billCardServe, markinCard } from '@/api/reception'
import { customerQueryKey } from '../../api/customer'
import Beaut from '@/components/recepion/beaut'
export default {
  components: {
    Beaut
  },
  data () {
    return {
      serveTab: [],
      mgid: '',
      mglevel: '',
      serveList: [],
      showServeList: false,
      userList: [],
      visible: false,
      userInfo: null,
      showUserInfo: false,
      userKey: '',
      beaShow: false,
      userShow: false,
      arr: [],
      beaIndex: '',
      showArrList: false,
      total: 0,
      showLink: false,
      serveLink: [],
      showbea: false,
      beaLink: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      markgrouGet().then(res => {
        if (res.status === 1) {
          this.serveTab = res.data.data
          this.mgid = this.serveTab[0].mgid
          this.mglevel = this.serveTab[0].mglevel
          this.getInit()
        } else {
          this.serveTab = []
        }
      })
    },
    // 选择侧边
    selectVal (item) {
      this.mgid = item.mgid
      this.mglevel = item.mglevel
      this.getInit()
    },
    // 套餐卡详情
    getInit () {
      markDetail({ mgid: this.mgid, mglevel: this.mglevel }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.serveList = res.data.data
            this.showServeList = true
          } else {
            this.showServeList = false
          }
        }
      })
    },
    // 搜索客户
    handGetList (val) {
      if (val !== '') {
        customerQueryKey({ username: val }).then(res => {
          if (res.status === 1) {
            if (res.data.data.length > 0) {
              this.userList = res.data.data
              this.visible = true
            } else {
              this.userList = []
              this.visible = false
            }
          }
        })
      } else {
        this.userList = []
        this.visible = false
      }
    },
    // 选择客户
    selectUser (item) {
      this.visible = false
      this.showUserInfo = true
      this.userKey = ''
      this.userInfo = { ...item }
    },
    // 套餐卡添加
    handAdd (item) {
      this.showArrList = true
      item.beaId = ''
      item.beaName = ''
      if(this.arr.length != 0) {
        if(this.arr.some(obj=>obj.mcaid===item.mcaid)) return this.$message.warning('同一套餐卡只能购买一次')
        this.arr.push(item)
      } else {
        this.arr.push(item)
      }
      this.total = this.getTotal()
    },
    // 计算总价
    getTotal(){
      let total = 0
      this.arr.forEach((item,index)=>{
        total += item.mcaprice
      })
      return total
    },
    // 套餐卡删除
    handDel (i) {
      this.arr.splice(i, 1)
      if (this.arr.length === 0) {
        this.showArrList = false
      }
      this.total = this.getTotal()
    },
    // 分配美容师 修改
    handBeat (i) {
      this.beaShow = true
      this.beaIndex = i
    },
    // 分配美容师[关闭]
    dialogClose () {
      this.beaShow = false
    },
    // 分配美容师[确认]
    selectConfirm (res) {
      this.beaShow = false
      this.arr[this.beaIndex].beaId = res.sbeid
      this.arr[this.beaIndex].beaName = res.sbecode
    },
    // 分配美容师[删除]
    handDeletd (item,i) {
      this.arr[i].beaId = ''
      this.arr[i].beaName = ''
      this.$set(this.arr, i, item)
    },
    // 收款
    handSub () {
      if (this.userInfo === null) {
        this.$message.warning('请选择客户信息')
      }  else {
        let mrr = []
        this.arr.forEach((item) => {
          const obj = {id: item.mcaid, sbeid: item.beaId}
          mrr.push(obj)
        })
        incardAdd({ userid: this.userInfo.userid, mcaid: mrr }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.$emit('addSuccess')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 关联抵扣券列表
    handShowCoun (i) {
      this.showbea = true
      markinCard({ mcaid: i.mcaid}).then(res => {
        if (res.status === 1) {
          this.beaLink = res.data.data
        }
      })
    },
    // 关闭本单
    handClose () {
      this.$emit('handClose')
    },
    // 关联服务
    handLink (item) {
      this.showLink = true
      billCardServe({ mcaid: item.mcaid}).then(res => {
        if (res.status === 1) {
          this.serveLink = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.billLeft,.billRig{
  width: 50%;
  height: 100%;
}
.billLeft{
  border-right: 4px solid #F7F9FB;
}
.billRig{ border-left: 4px solid #F7F9FB; }
.leftCont{
  width: 100%;
  height: calc(100% - 40px);
  padding: 16px;
  box-sizing: border-box;
}
.leftTabBox{
  width: 140px;
  height: 100%;
  overflow: hidden;
}
.leftList{
  width: calc(100% - 180px);
  height: 100%;
  position: relative;
}
.productItem{
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  padding: 8px 0px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}
.rebeat {
  font-size: 12px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #3C84EF;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.proItem {
  width: 98%;
  height: 96px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  padding: 8px 0px;
  box-sizing: border-box;
}
.item1 {
  width: 140px;
  height: 96px;
  background: linear-gradient(131deg, #14151D 0%, #212433 100%);
  border-radius: 3px 0px 0px 3px;
  padding: 25px;
  box-sizing: border-box;
  text-align: center;
  line-height: 25px;
}
.item1_one {
  font-size: 18px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #FCDEA0;
}
.item1_two {
  font-size: 12px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #F5AB76;
  border-radius: 22px;
  border: 1px solid #F5AB76;
  cursor: pointer;
}
.item2 {
  margin-left: 20px;
  line-height: 25px;
}
.item2_one {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.item2_two, .item2_three {
  font-size: 12px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #999999;
}
.closebtn {
  width: 90px;
  height: 40px;
  font-size: 13px;
  font-family: 'PingFang SC-Medium, PingFang SC';
  font-weight: 500;
  color: #A5B7CC;
}
.userBox, .reRow {
  padding: 16px 50px 16px;
  box-sizing: border-box;
  border-bottom: 4px solid #F7F9FB;
}
.userInfo{
  height: 50px;
}
.userItem{
  width: 33.3%;
  margin-bottom: 16px;
}
.userRow{
  margin: 8px 0px;
  cursor: pointer;
}
.userItem span{
  display: inline-block;
  color: #576271;
}
.userItem:last-child{
  margin-bottom: 0px;
}
.userItem .utValue{
  width: 65%;
  background: #F7F9FB;
  height: 40px;
  line-height: 40px;
  padding-left: 12px;
  box-sizing: border-box;
  color: #333;
}
.cardBox {
  width: 100%;
  height: calc(100% - 250px);
  position: relative;
  padding: 0px 16px;
  box-sizing: border-box;
}
.beatop {
  width: 148px;
  height: 96px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  padding: 8px 0px;
  box-sizing: border-box;
  text-align: center;
  line-height: 20px;
}
.beabot {
  padding: 0px 0px;
  text-align: center;
  box-sizing: border-box;
  line-height: 55px;
}
.beatop img {
  width: 30px;
  height: 37px;
  margin-top: 10px;
}
.beatop p {
  font-size: 12px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #3C84EF;
  cursor: pointer;
}
.codeBox {
  width: 148px;
  height: 55px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  margin-bottom: 5px;
}
.edbtn, .delbtn {
  width: 72px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
}
.edbtn {
  background: #3C84EF;
}
.delbtn {
  background: #F44C8E;
}
.summary {
  padding: 16px 30px 16px;
  box-sizing: border-box;
  position: absolute;
  bottom: 16px;
}
.numary {
  width: 663px;
  height: 70px;
  background: #F7F9FB;
  border-radius: 4px;
  padding: 30px;
  box-sizing: border-box;
  margin-right: 10px;
  font-size: 18px;
}
.sumbtn {
  width: 120px;
  height: 70px;
  background: #3C84EF;
  border-radius: 4px;
  color: #fff;
  line-height: 70px;
  cursor: pointer;
  text-align: center;
}
.card_top {
  margin-top: 10px;
}
li .el-menu-item {
  padding-left: 20px !important;
}
.serveDialog >>>.el-table__body-wrapper::-webkit-scrollbar {
	width: 0;
}
</style>
