<template>
<!-- 套餐卡 -->
<div class="box100">
  <div class="whiteBg"  v-if="!cardShow">
    <!-- <div class="settingAdd" @click="handBuy"><span>立即购买</span></div> -->
    <div class="settingBlue" @click="handResh"><span>刷新</span></div>
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="username"  placeholder="请输入账号/手机号" class="inputSearch"></el-input>
      <el-date-picker
        v-model="time"
        type="daterange"
        value-format="yyyy-MM-dd"
        class="searchDate"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
      <el-button class="btnReset" @click="handleReset">重置</el-button>
    </div>
    <div class="table_list">
      <el-table
        :data="cardList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="username">
        </el-table-column>
        <el-table-column
          label="绑定手机"
          prop="uphone">
        </el-table-column>
        <el-table-column
          label="购买套餐"
          prop="icamark">
        </el-table-column>
        <el-table-column
          label="价格"
          prop="mcaprice">
        </el-table-column>
        <el-table-column
          label="美容师"
          prop="sbecode">
        </el-table-column>
        <el-table-column
          label="时间"
          prop="creatime">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope" v-if="roleBtn[1].power">
            <el-button type="text" size="small" @click="handDetail(scope.row)" v-if="scope.row.sbeid === ''">分配美容师</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>

    <!-- 分配美容师 -->
    <el-dialog title="分配美容师" class="baetit" :visible.sync="beaShow" width="1200px" center  :close-on-click-modal="false">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
  </div>
  <!-- 套餐卡添加 -->
  <div class="whiteBg" v-if="cardShow" style="padding: 0px;">
    <Card @handClose="handClose"  @addSuccess="addSuccess"></Card>
  </div>
</div>
</template>

<script>
import Beaut from '@/components/recepion/beaut'
import Card from '@/components/recepion/card'
import { incardGet, insbeUpdate } from '@/api/reception'
export default {
  name: 'receptionCard',
  props: ['roleBtn'],
  components: {
    Beaut,
    Card
  },
  data () {
    return {
      username: '',
      time: null,
      page: 1,
      size: 10,
      total: 0,
      cardList: [],
      beaShow: false,
      cardShow: false,
      icaid: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let st = ''
      let et = ''
      if (this.time !== null) {
        st = this.time[0]
        et = this.time[1]
      }
      const params = {
        username: this.username,
        icastime: st,
        icaetime: et,
        page_index: this.page,
        page_size: this.size
      }
      incardGet(params).then(res => {
        if (res.status === 1) {
          this.cardList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.cardList = []
          this.total = 0
        }
      })
    },
    // 刷新
    handResh () {
      this.init()
    },
    // 立即购买
    handBuy () {
      this.cardShow = true
    },
    // 购买成功
    addSuccess () {
      this.cardShow = false
      this.init()
    },
    handClose () {
      this.cardShow = false
    },
    // 分配美容师
    handDetail (row) {
      this.beaShow = true
      this.icaid = row.icaid
    },
    // 分配美容师[关闭]
    dialogClose () {
      this.beaShow = false
    },
    // 分配美容师[确认]
    selectConfirm (res) {
      this.beaShow = false
      insbeUpdate({ icaid: this.icaid, sbeid: res.sbeid }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pageChange (page) { // 翻页
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () { // 重置
      this.username = ''
      this.time = null
      this.init()
    },
  }
}
</script>

<style scoped>
.baetit {
  font-size: 18px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #333333;
}
</style>
